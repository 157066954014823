<template>
	<ValidationObserver ref="observer" v-slot="{ handleSubmit }">
		<form ref="form" @submit.prevent="handleSubmit(saveCategory)" autocomplete="off">
			<header class="modal-card-head">
				<h4 class="modal-card-title">
					<span>{{ $t(modalTitle(name)) }} <strong>{{ $tc('menu.categories') }}</strong></span>
				</h4>
			</header>
			<div class="modal-card-body">
				<b-loading :is-full-page="false" v-model="isOpening"></b-loading>
				<div class="modal-card mb-3">
                    <div class="columns is-multiline is-mobile mb-0">
						<div class="column is-12-mobile is-9-tablet">
							<InputWithValidation :label="$tc('fields.name')" rules="required" v-model="form.name" />
						</div>
						<div class="column is-12-mobile is-3-tablet">
							<div class="columns">
								<div class="column is-12-mobile is-6-tablet">
									<div class="field preview">
										<label class="label">{{ $t('fields.icon') }}</label>
										<div class="icon" :class="{ 'is-valid': icon }">
											<img v-if="icon" loading="lazy" :src="icon" />
											<div v-else class="vue-swatches__diagonal"></div>
											<input class="input" type="file" accept="image/*" @change="updateIcon" /> 
										</div>
									</div>
								</div>
								<div class="column is-12-mobile is-6-tablet">
									<b-field :label="$tc('fields.color')" class="mb-2">
										<v-swatches v-model="form.color" :swatches="swatches" row-length="5" popover-x="left" show-fallback fallback-input-type="color"></v-swatches>
									</b-field>
								</div>
							</div>
						</div>
					</div>

                    <InputWithValidation class="mb-4" field="textarea" :label="$tc('fields.description')" rules="required" v-model="form.description" />

                    <div class="columns is-multiline is-mobile mb-3">
						<div v-if="types.length >= 1" class="column is-12-mobile is-6-tablet">
							<SelectMultiple :input_label="$tc('menu.types', 2)" v-model="form.types" :options="types" track-by="name" label="name" />
						</div>
						<div v-if="categories.length >= 1" class="column">
							<SelectWithValidation :label="$tc('labels.hierarchy')" v-model="form.category_id">
								<option v-for="c in categories" :value="c.id" :key="c.id">{{ c.name }}</option>">
							</SelectWithValidation>
						</div>
					</div>

					<div class="field preview">
						<label class="label">{{ $tc('fields.image') }}</label>
						<div class="image" :class="{ 'is-valid': image }">
							<img v-if="image" loading="lazy" :src="image" />
							<input class="input" type="file" accept="image/*" @change="updateImage" />
						</div>
					</div>

					<small class="modal-updated" v-if="form.updated_at">{{ $t('labels.last_change') }} {{ format(form.updated_at) }}</small>
				</div>
			</div>
			<footer class="modal-card-foot">
				<b-button class="is-rounded is-outlined is-danger" @click="$emit('close')">{{ $t('buttons.close') }}</b-button>
				<b-button native-type="submit" class="is-rounded is-primary" :loading="loading">{{ $t('buttons.save') }}</b-button>
			</footer>
		</form>
	</ValidationObserver>
</template>

<script>
import InputWithValidation from '@/components/inputs/InputWithValidation'
import SelectWithValidation from '@/components/inputs/SelectWithValidation'
import SelectMultiple from '@/components/inputs/SelectMultiple'
import { ValidationObserver } from 'vee-validate'
import Api from '@/services/api'
import eventHub from '@/services/eventHub'
import { successToast, errorToast } from '@/mixins/toast'
import '@/mixins/generic'
import VSwatches from 'vue-swatches'

export default {
	components: {
		InputWithValidation,
        SelectWithValidation,
        SelectMultiple,
		ValidationObserver,
        VSwatches
	},
	props: {
		id: {
			type: Number,
			required: false
		},
		name: {
			type: String,
			required: true
		},
		root: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			isOpening: false,
			loading: false,
            swatches: this.defaultColors(),
			permission: [],
			visible: false,
            types: [],
            categories: [],
			form: {
				name: '',
				color: '',
				description: '',
				image: null,
				icon: null,
                types: [],
				category_id: null
			},
			image: null,
			icon: null,
			configRequest: {
				headers: {
					'Content-Type': 'multipart/form-data'
				}
			}
		}
	},
	methods: {
		async update() {
			try {
				this.loading = true

				let formData = new FormData()

				if (this.form.image) {
					formData.append('image', this.form.image)
				}

				if (this.form.icon) {
					formData.append('icon', this.form.icon)
				}

				for (var i = 0; i < this.form.types.length; i++) {
					formData.append('types[]', this.form.types[i].id)
				}

				if (this.form.category_id) {
                    formData.append('category_id', this.form.category_id)
                }

				formData.append('_method', 'put')
				formData.append('name', this.form.name)
				formData.append('color', this.form.color || '')
				formData.append('description', this.form.description || '')

				const response = await Api.post(`categories/update/${this.id}`, formData, this.configRequest)
				const { status } = response
				if ([200, 201].includes(status)) {
					this.$emit('close')
					history.pushState({}, '', '/categories')
					successToast(this.$t('alerts.update.success_f', [this.$tc('menu.categories')]))
					eventHub.$emit('reload-categories')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					errorToast(this.$t('alerts.update.error_f', [this.$tc('menu.categories')]))
				}
			} finally {
				this.loading = false
			}
		},
		async store() {
			try {
				this.loading = true

				let formData = new FormData()

				if (this.form.image) {
					formData.append('image', this.form.image)
				}

				if (this.form.icon) {
					formData.append('icon', this.form.icon)
				}

                for (var i = 0; i < this.form.types.length; i++) {
					formData.append('types[]', this.form.types[i].id)
				}

				if (this.form.category_id) {
                    formData.append('category_id', this.form.category_id)
                }

				formData.append('name', this.form.name)
				formData.append('color', this.form.color || '')
				formData.append('description', this.form.description || '')

				const response = await Api.post('categories/store', formData, this.configRequest)
				const { status } = response
				if ([200, 201].includes(status)) {
					this.$emit('close')
					history.pushState({}, '', '/categories')
					successToast(this.$t('alerts.create.success_f', [this.$tc('menu.categories')]))
					eventHub.$emit('reload-categories')
				}
			} catch (e) {
				const { status } = e
				if (status === 422) {
					const { message } = e.data
					successToast(this.$t('alerts.create.error_f', [this.$tc('menu.categories')]) + '<small>' + message + '</small>')
				}
			} finally {
				this.loading = false
			}
		},
		async saveCategory() {
			this.name === 'New' ? await this.store() : await this.update()
		},
		async findById() {
			if (this.name === 'Edit') {
				this.isOpening = true
				try {
					const response = await Api.get(`categories/findById/${this.id}`)
					if (response.status === 200) {
						const { data } = response
						this.form.name = data.name
						this.form.description = data.description
						this.form.color = data.color
                        this.form.types = data.types
						this.form.category_id = data.category_id
						this.image = data.image
						this.icon = data.icon
						this.isOpening = false
					}
				} catch (e) {
					console.log(e)
				}
			}
		},
        async findAllTypes() {
            try {
                const response = await Api.get('types/findAll')
                if (response.status === 200) {
                    this.types = response.data
                }
            } catch (e) {
                console.log(e)
            }
        },
        async findAllCategories() {
			this.categories = []
			this.isOpening = true
			try {
				const response = await Api.get('categories/findAll')
				if (response.status === 200) {
					this.categories = response.data
				}
			} catch (e) {
				console.log(e)
				errorToast(this.$t('alerts.load_error'))
			} finally {
				this.isOpening = false
			}
        },
		async updateIcon (e) { 
			const file = e.target.files[0]
			this.icon = await this.readFile(file)
			this.form.icon = file
		},
		async updateImage (e) { 
			const file = e.target.files[0]
			this.image = await this.readFile(file)
			this.form.image = file
		}
	},
	mounted() {
		this.findById()
        this.findAllTypes()
        this.findAllCategories()
	}
}
</script>
